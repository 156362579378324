import {BankAccountDTO_OLD} from "@/dto/BankDetailsDTO_OLD";
import AddressDTO from "@/dto/AddressDTO";
import BankAccountDTO from "@/dto/BankAccountDTO";
import {FederalTaxClassification} from "@/constants/FederalTaxClassification";
import { FillableDocumentStatus } from "@/dto/archive/FillableDocumentStatus";

export default class SaveW9RequestDTO {

    public id: number | null = null;
    public status: FillableDocumentStatus | null = null;
    public sublimeFilling: boolean | null = null;
    public signerPublicId: string | null = null;
    public publicId: string | null = null;
    public name: string | null = null;
    public businessName : string | null = null;
    public classification: FederalTaxClassification | null = null;
    public llcTaxClassification: LLCTaxClassification | null = null;
    public classificationOtherDescription: string | null = null;
    public haveAnyPartners: boolean | null = null;
    public payeeExemptionCode: number | null = null;
    public fatcaExemptionCode: number | null = null;
    public address = new AddressDTO();
    public bankAccount = new BankAccountDTO();
    public ssn: string | null = null;
    public ein: string | null = null;

    public merge(obj: SaveW9RequestDTO) {
        if (obj.name) {
            this.name = obj.name;
        }
        if(obj.ein){
            this.ein = obj.ein;
        }
        if (obj.businessName) {
            this.businessName = obj.businessName;
        }
        if (obj.ssn) {
            this.ssn = obj.ssn;
        }
        if (obj.address) {
            this.address = obj.address;
        }
    }

}
export enum LLCTaxClassification {
    C = 'C',
    S = 'S',
    P = 'P'
}
